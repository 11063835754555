<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkDropdown from "@components/input/AkDropdown";
import BilanCircuit from "@views/bilan/circuit/components/BilanCircuit";
import Toast from 'primevue/toast';

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCircuitService from "@services/bilanCircuitService";
import agerService from "@services/agerService";
import uerService from "@services/uerService";
import ceiService from "@services/ceiService";
import circuitService from "@services/circuitService";
import rolePermissionService from "@services/rolePermissionService";
import affectationService from "@services/affectationService";

export default {
  components: {AkFormView, AkFormSubmitted, AkDropdown, BilanCircuit, Toast},
  mixins: [randomRef, roleMixin],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "bilan.create",
    }
  },
  data() {
    return {
      submitted: false,
      alreadyExists: false,
      agerList: [],
      uerList: [],
      ceiList: [],
      circuitList: [],
      disableAger: null,
      disableUer: null,
      disableCei: null,
      current: {
        ceiId: null,
        uerId: null,
        agerId: null,
      },
      circuitRows: [],
      copy: {
        data: null,
      }
    };
  },
  validations() {
    return {
      current: {
        agerId: {required},
        uerId: {required},
        ceiId: {required},
      }
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
    
    let p4 = circuitService.findAllCurrent();
    p4.then(data => this.circuitList = data);
  
    let p5 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.circuitReview.edit);

    let p6 = affectationService.findAllByUserId(this.currentUser.userId);
    p6.then(data => {
      this.affectations = data.affectations;
      this.agerList = data.agerList;
      if (this.agerList.length === 1) {
        this.current.agerId = this.agerList[0].id;
        this.disableAger = true;
      }

      this.uerList = data.uerList;
      if (this.uerList.length === 1) {
        this.current.uerId = this.uerList[0].id;
        this.disableUer = true;
      }

      this.ceiList = data.ceiList;
      if (this.ceiList.length === 1) {
        this.current.ceiId = this.ceiList[0].id;
        this.disableCei = true;
      }
    });

    Promise.all([p4, p5, p6]).then(()=>{
      this.getRef().hideLoader();
    });
  },
  methods: {
    create() {
      if(this.circuitListForCurrent.length === 0) {
        this.getRef().error(this.$t("bilan.error.no_circuits_cei"));
        return;
      }

      if (!this.checkForm()) {
        this.getRef().error(this.$t("bilan.error.create"));
        return;
      }
  
      let promises = [];
      for (const element of this.circuitRows) {
        element.agerId = this.current.agerId;
        element.uerId = this.current.uerId;
        element.ceiId = this.current.ceiId;
        let p = bilanCircuitService.create(element);
        promises.push(p);
        p.catch(e=>{
          this.getRef().hideLoader();
          this.getRef().error(this.$t("error."+e.response.data.error));
          this.submitted = false;
          this.validationSubmitted = false;
        });
      }
      Promise.all(promises).then(data=>{
        let msg = this.$t("bilan_circuit.added");
        this.$router.replace({ path: `/bilan/circuit/${data[0].ceiId}/details`, query: { msg: msg } });
      });
    },
    initCircuitRows() {
      this.circuitRows = [];
      for (const element of this.circuitListForCurrent) {
        let circuit = element;
        this.circuitRows.push({
          circuitId: circuit.id,
          agerId: circuit.agerId,
          uerId: circuit.uerId,
          ceiId: circuit.ceiId,
          number: circuit.number,
          section: circuit.section,
          axis: circuit.axis,
        });
      }
    },
    copyRow(index) {
      let row = this.circuitRows[index];
      this.copy.data = {
        nbConduite: row.nbConduite,
        lineaireTotal: row.lineaireTotal,
        lineaireSalage: row.lineaireSalage,
        surfaceSalage: row.surfaceSalage,
        dureeEstimeTraitement: row.dureeEstimeTraitement,
        dureeEstimeParcours: row.dureeEstimeParcours,
        tonnageEstimeSel: row.tonnageEstimeSel,
        capaciteSaleuse: row.capaciteSaleuse
      }
      this.$toast.add({severity:'success', summary: this.$t("form.row_copied"), life: 1000});
    },
    pasteRow(index) {
      if (this.copy.data) {
        let row = this.circuitRows[index];
        row.nbConduite = this.copy.data.nbConduite;
        row.lineaireTotal = this.copy.data.lineaireTotal;
        row.lineaireSalage = this.copy.data.lineaireSalage;
        row.surfaceSalage = this.copy.data.surfaceSalage;
        row.dureeEstimeTraitement = this.copy.data.dureeEstimeTraitement;
        row.dureeEstimeParcours = this.copy.data.dureeEstimeParcours;
        row.tonnageEstimeSel = this.copy.data.tonnageEstimeSel;
        row.capaciteSaleuse = this.copy.data.capaciteSaleuse;
        this.$toast.add({severity:'success', summary: this.$t("form.row_pasted"), life: 1000});
      } else {
        this.$toast.add({severity:'error', summary: this.$t("form.no_row_copied"), life: 3000});
      }
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    },
    testExistence(ceiId) {
      bilanCircuitService.findBoolByCeiId(ceiId).then(data => {
        if (data) {
          this.getRef().resetMessages();
          this.alreadyExists = false;
        } else {
          this.getRef().resetMessages();
          this.alreadyExists = true;
          this.getRef().error("Ce bilan existe déjà pour ce CEI");
        }
      });
    }
  },
  computed: {
    uerListForCurrent() {
      return this.uerList.filter(uer => uer.agerId === this.current.agerId);
    },
    ceiListForCurrent() {
      return this.ceiList.filter(cei => cei.uerId === this.current.uerId);
    },
    circuitListForCurrent() {
      return this.circuitList.filter(c => c.ceiId === this.current.ceiId);
    }
  },
  watch: {
    uerListForCurrent() {
      if (!this.uerListForCurrent.some(uer => uer.id === this.current.uerId))
        this.current.uerId = undefined;
    },
    ceiListForCurrent() {
      if (!this.ceiListForCurrent.some(cei => cei.id === this.current.ceiId))
        this.current.ceiId = undefined;
    },
    circuitListForCurrent() {
      this.initCircuitRows();
    },
    'current.ceiId'(newValue) {
      this.alreadyExists = false;
      if (newValue) this.testExistence(newValue);
    }
  }
}
</script>

<template v-if=!roleLoading>
  <Toast/>
  <AkFormView :ref="ref" :title="$t('bilan.create')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkDropdown :label="$t('ager_label')"
                      v-model="current.agerId"
                      :validator="v$.current.agerId"
                      :submitted=this.submitted
                      :options=this.agerList
                      :disabled=disableAger
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkDropdown :label="$t('uer_label')"
                      v-if="current.agerId"
                      v-model="current.uerId"
                      :validator="v$.current.uerId"
                      :submitted=this.submitted
                      :options=this.uerListForCurrent
                      :disabled=disableUer
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
          <AkDropdown :label="$t('cei_label')"
                      v-if="current.uerId"
                      v-model="current.ceiId"
                      :validator="v$.current.ceiId"
                      :submitted=this.submitted
                      :options=this.ceiListForCurrent
                      :disabled=disableCei
                      option-value="id"
                      option-label="label"
                      class-name="col-md-4"/>
        </div>
        
        <div class="mt-2 row" v-if="!alreadyExists">
          <BilanCircuit v-for="(row, index) in circuitRows" :key="index" :row="row" :index="index" ref="panels" @copyRow="copyRow" @pasteRow="pasteRow"/>
        </div>
        
        <div class="float-right">
          <button v-if="!alreadyExists && this.canCreateCircuit()" @click=create() class="btn btn-primary" style="margin-left: 10px">{{ $t('save') }}</button>
        </div>
      </AkFormSubmitted>
    </template>
  </AkFormView>
</template>